import {watch} from "vue";
import {generalData} from "store";
import {postDataAsync} from "helpers/_api";

import { apiHostname } from "config";

async function chainPromiseCalls(asyncFunctions = [], respectiveParams = []) {
	for (let i = 0; i < asyncFunctions.length; i++) {
		const eachResult = await asyncFunctions[i](...respectiveParams[i]);
		// do what you want to do with each result
	}
	return;
}
async function chainPromiseCallsWithoutParameters(asyncFunctions = [], respectiveParams = []) {
	for (let i = 0; i < asyncFunctions.length; i++) {
		const eachResult = await asyncFunctions[i]();
		// do what you want to do with each result
	}
	return;
}


const productIdMap = {
	"Energielabel": {
		"Woning": 300,
		"Nieuwbouw": 399,
		"Utiliteitsbouw": 400
	},
	"Bouwkundige keuring": {
		"Woning": 299,
		"Nieuwbouw": 299,
		"Utiliteitsbouw": 299
	},
	"Maatwerkadvies": {
		"Woning": 401,
		"Nieuwbouw": 401,
		"Utiliteitsbouw": 402
	},
};
const extraServiceIdMap = {
	"Verbouwadvies": 310,
	"Verduurzamingslabel": 403
};
// Following extra's cant be selected within the form
// NTA (NTA-installatie keuring)
// MJOP (Meerjaren Onderhoudsplan 10 jaar)
// ENG (Engelstalig rapport)

const settingPropertyMap = {
	"referralid": 90,
	"shortcode": 95,
	"type": 81,
	"value": 82,
	"payout": 84,
	"payout_frequency": 91
};
const getByPropertyIdAndValue = (key, value, target_name = "ambassadeur") => {
	const propertyId = settingPropertyMap[key];
	return Object.values(generalData.settings[target_name]).find(a => a.some(b => (`${b.workflowpropertyfield_id}` === `${propertyId}`) && (`${b.value}` === `${value}`)));
};
const getValueByPropertyKey = (data, key) => {
	const id = settingPropertyMap[key];
	if (!id) {
		console.error("Property not found in settingPropertyMap", key);
		return;
	}
	return data.find(a => a.workflowpropertyfield_id === id)?.value ?? undefined;
};

export const tmpInput = {
	"aanmelding_referralid": 10933845845,
	"aanmelding_ambassadeur": "",
	"aanmelding_postcode": "4871NS",
	"aanmelding_huisnummer": "10",
	"aanmelding_toevoeging": "",
	"aanmelding_woningtype": "huis",
	"aanmelding_interests": "Energielabel, Bouwkundige keuring",
	"aanmelding_services": "Verduurzamingsadvies, Verbouwadvies, Verduurzamingslabel",
	"aanmelding_sustainability": "",
	"aanmelding_opmerking": "test",
	"aanmelding_uitvoering": "",
	"aanmelding_budget": "",
	"aanmelding_construction": "Wel bouwbegeleiding",
	"aanmelding_aanhef": "Mr.",
	"aanmelding_firstname": "Lucas",
	"aanmelding_lastname": "Halters",
	"aanmelding_email": "test@test.nl",
	"aanmelding_telefoonnummer": "0612345678",
	"aanmelding_opmerkingen": "test",
	"aanmelding_energielabel": "E",
	"aanmelding_bouwjaar": 1915,
	"aanmelding_oppervlakte": 244,
	"aanmelding_straatnaam": "Pleinstraat",
	"aanmelding_plaats": "Etten-Leur"
};

const tmpContact = {
	"_links": {
		"overview": {
			"href": "https://jobse.workflow-manager.dev/api/v1.0/workflowcard"
		},
		"item": {
			"href": "https://jobse.workflow-manager.dev/api/v1.0/workflowcard/375"
		},
		"children": {
			"href": "https://jobse.workflow-manager.dev/api/v1.0/workflowcard/375/workflowcard"
		},
		"links": {
			"href": "https://jobse.workflow-manager.dev/api/v1.0/workflowcard/375/links"
		},
		"images": {
			"href": "https://jobse.workflow-manager.dev/api/v1.0/workflowcard/375/images"
		},
		"message": {
			"href": "https://jobse.workflow-manager.dev/api/v1.0/workflowcard/375/message"
		}
	},
	"_meta": {
		"timestamp": "2024-12-12 12:44:00",
		"verb": "POST",
		"version": "v1.0",
		"latest-version": "v1.0",
		"performance": {
			"duration": 1.0965828895568848,
			"start": 1734003839.564727,
			"end": 1734003840.66131
		},
		"total": null
	},
	"data": {
		"id": 375,
		"import_key": null,
		"creation_date": null,
		"version_date": null,
		"version_user": null,
		"owner": null,
		"state": "Online",
		"workflowcardtype_id": 1,
		"assignee": null,
		"priority": "medium",
		"title": "Wesley Test",
		"seq": 375
	},
	"_allow": [
		"GET"
	],
	"extra": {
		"workflowboard": {
			"id": 1
		},
		"workflowcardtype": {
			"id": 1,
			"title": "Contact"
		},
		"workflowproperties": [
			{
				"id": 6440,
				"field": {
					"id": 36,
					"type": "RadioField",
					"name": "aanmelding_aanhef",
					"label": "Aanhef",
					"seq": 24
				},
				"value": "heer",
				"seq": 8984
			},
			{
				"id": 6441,
				"field": {
					"id": 2,
					"type": "TextField",
					"name": "contact_voornaam",
					"label": "Voornaam",
					"seq": 2
				},
				"value": "Wesley",
				"seq": 8985
			},
			{
				"id": 6442,
				"field": {
					"id": 3,
					"type": "TextField",
					"name": "contact_achternaam",
					"label": "Achternaam",
					"seq": 3
				},
				"value": "Test",
				"seq": 8986
			},
			{
				"id": 6443,
				"field": {
					"id": 4,
					"type": "EmailField",
					"name": "contact_emailadres",
					"label": "Emailadres",
					"seq": 4
				},
				"value": "wesley@ibizzamaila.nl",
				"seq": 8987
			},
			{
				"id": 6444,
				"field": {
					"id": 5,
					"type": "PhoneField",
					"name": "contact_telefoonnummer",
					"label": "Telefoonnummer",
					"seq": 5
				},
				"value": "0612345678",
				"seq": 8988
			},
			{
				"id": 6445,
				"field": {
					"id": 1,
					"type": "RadioField",
					"name": "contact_status",
					"label": "Status contact",
					"seq": 1
				},
				"value": "Actief",
				"seq": 8989
			},
			{
				"id": 6446,
				"field": {
					"id": 69,
					"type": "TextField",
					"name": "teamleader_contact_id",
					"label": "Teamleader Contact ID (Contactpersoon)",
					"seq": 67
				},
				"value": "4f9e74ed-1f5d-0438-be70-e336e4508be1",
				"seq": 8990
			}
		]
	},
	"images": []
};


const keyMap = {
	"contact": {
		"aanmelding_firstname": "contact_voornaam",
		"aanmelding_lastname": "contact_achternaam",
		"aanmelding_email": "contact_emailadres",
		"aanmelding_telefoonnummer": "contact_telefoonnummer"
	},
	"comission": {}
};

const resolveData = (key, data) => {
	if (!keyMap[key]) {
		console.error("Key not found in keyMap", key);
		return data;
	}
	const result = JSON.parse(JSON.stringify(data));
	Object.keys(keyMap[key]).forEach((name) => {
		result[keyMap[key][name]] = data[name];
		delete result[name];
	});
	return result;
};

// "aanmelding_woningtype": "huis" of "bedrijf"
// "aanmelding_interests": "Energielabel, Bouwkundige keuring",
// "aanmelding_services": "Verduurzamingsadvies, Verbouwadvies, Verduurzamingslabel",
// "aanmelding_sustainability": "Zonnepanelen, CV ketel, Dakisolatie, Vloerverwarming, Vloerisolatie, Spouwmuur isolatie, HR++ glas, Warmtepomp, Overig",

const resolveInterests = (data) => {

	// aanmelding_woningtype used as input for defining the main products
	// aanmelding_services can be used for defining the extra services or setting Maatwerkadvies as main product

	// Energielabel -> (Normaal, Nieuwbouw dit jaar of vorig jaar?, Utiliteitsbouw = bedrijf)
	// Bouwkundige keuring -> (Normaal)
	// Maatwerkadvies (Woning = huis, Utiliteitsbouw = bedrijf) // Gebruiken we onder extra's verduurzamingslabel voor
	// Extra's die zorgen voor maatwerkadvies  (Verbouwadvies, Verduurzamingsadvies)

	// Verduurzamingslabel gebruiken we voor Maatwerkadvies (Woning = huis, Utiliteitsbouw = bedrijf)

	const interests = data.aanmelding_interests?.split(", ") ?? [];
	if (interests.includes("Verduurzamingsadvies")) {
		interests.push("Maatwerkadvies");
	}
	return interests;
};
const resolveInterestType = (data) => {
	const typeMap = {
		"huis": "Woning",
		"bedrijf": "Utiliteitsbouw"
	};
	if (data.aanmelding_bouwjaar >= new Date().getFullYear() - 1) {
		return "Nieuwbouw";
	}
	return typeMap[data.aanmelding_woningtype];
};
const resolveProductId = (interest, type) => {
	return productIdMap[interest]?.[type] ?? null;
};
const resolveExtraServiceId = (interest) => {
	return extraServiceIdMap?.[interest] ?? null;
};
const resolveProductById = (id) => {
	return generalData.settings.product[id];
};
const resolveDealname = (data, product) => {

	const parts = [];

	if (product) {
		const shortcode = getValueByPropertyKey(product, "shortcode");
		if (shortcode) {
			parts.push(shortcode);
		}
	}

	resolveServices(data).forEach((service) => {
		const extraServiceId = resolveExtraServiceId(service);
		const extraService = resolveProductById(extraServiceId);
		if (!extraService) return;

		const shortcode = getValueByPropertyKey(extraService, "shortcode");
		if (!shortcode) return;

		parts.push(`+ ${shortcode}`);
	});


	parts.push(`${data.aanmelding_plaats}`);
	parts.push(`-`);
	parts.push(`${data.aanmelding_straatnaam}`);
	parts.push(`${data.aanmelding_huisnummer}${data.aanmelding_toevoeging}`);

	return parts.join(" ");
};
const resolveCommissionValue = (data, ambassador, product) => {
	if (!product) return "";

	console.log("@TODO use ambassador information for overruling CommissionValue", ambassador);

	const type = getValueByPropertyKey(product, "type");
	if (`${type}` !== "Vast bedrag") {
		return "";
	}
	return getValueByPropertyKey(product, "value") ?? 0;
};
const resolveCommissionPercentage = (data, ambassador, product) => {
	if (!product) return "";

	console.log("@TODO use ambassador information for overruling CommissionPercentage", ambassador);

	const type = getValueByPropertyKey(product, "type");
	if (`${type}` !== "Percentage") {
		return "";
	}
	return getValueByPropertyKey(product, "value") ?? 0;
};
const resolveCommissionPayout = (data, ambassador, product) => {
	if (!product) return "";

	// Unused at the moment, not sure how to couple.
	// ambassadeurs_commissie_uitbetalingswijze -> aanmelding_commissie_uitbetalingswijze
	if (ambassador) {
		const payout = getValueByPropertyKey(ambassador, "payout");
		if (payout) {
			return payout;
		}
	}

	return "Anders";
};
const resolveCommissionPayoutElse = (data, ambassador, product) => {
	if (!product) return "";

	// Unused at the moment, not sure how to couple.

	return "";
};

const resolveServices = (data) => {
	const services = data.aanmelding_services.split(", ");
	return services.filter(a => {
		// Since Verduurzamingsadvies is marked as Maatwerkadvies we need to filter it out.
		return a !== "Verduurzamingsadvies";
	});
};

const postContact = (data) => {
	return new Promise((resolve, reject) => {
		const contactData = resolveData("contact", data);
		postDataAsync(`${apiHostname}/api/v1.0/workflowform/1/submit`, contactData).then(
			(contact) => {
				console.log("contact", contact);
				resolve(contact);
			},
			(json) => {
				console.log("json error", json);
				reject(json);
			}
		);
	});
};

const postDeals = (data, contactData, ambassador) => {
	return new Promise((resolve, reject) => {
		const contactId = contactData.data.id;
		const contactTeamleaderId = contactData.extra.workflowproperties.find(a => a.field.name === "teamleader_contact_id").value;
		// Define our main products
		const interests = resolveInterests(data);
		const interestType = resolveInterestType(data);

		// Now build our promise list, split the deals based on data.
		const promisses = [];
		interests.forEach((interest) => {
			promisses.push(() => {
				return new Promise((res, rej) => {
					const productId = resolveProductId(interest, interestType);
					const product = resolveProductById(productId);
					// console.log("productId",productId, resolveDealname(data, interest, interestType));
					// console.log("contactId", contactId);
					// console.log("contactTeamleaderId", contactTeamleaderId);
					// console.log("data", data);
					//
					// console.log("interest",interest, product);

					const commissionData = resolveData("comission", data);
					commissionData.aanmelding_contactpersoon = contactId;

					// commissionData.aanmelding_ambassadeur =
					commissionData.teamleader_deal_customer_type = "contact";
					commissionData.teamleader_deal_contact_id = contactTeamleaderId;

					// In case of a company we need to add our contact_id seperately, since we used deal_contact_id for the company
					if (commissionData.teamleader_deal_customer_type === "company") {
						commissionData.teamleader_contact_id = contactTeamleaderId;
					}

					if (ambassador && Array.isArray(ambassador) && ambassador?.length) {
						// Set our coupled workflowcard based on input matched by referralid
						commissionData.aanmelding_ambassadeur = ambassador[0].workflowcard_id;
					}

					// Prefill the correct product.
					commissionData.aanmelding_commissie_product = productId ?? "";

					if (product) {
						commissionData.aanmelding_dealnaam = resolveDealname(data, product);

						commissionData.aanmelding_commissie_bedrag = resolveCommissionValue(data, ambassador, product);
						commissionData.aanvraag_commissie_percentage = resolveCommissionPercentage(data, ambassador, product);
						commissionData.aanmelding_commissie_uitbetalingswijze = resolveCommissionPayout(data, ambassador, product);
						// commissionData.aanmelding_uitbetalingswijzeanders = resolveCommissionPayoutElse(data, ambassador, product);
					}

					// console.log("commissionData", commissionData);

					// Post the commission deal
					// console.log("@TODO reactivate");
					postDataAsync(`${apiHostname}/api/v1.0/workflowform/10/submit`, commissionData).then(
						(data) => {
							res(data);
						},
						(json) => {
							console.log("json error", json);
							rej();
						}
					);
				});
			});
		});
		// Now post all deals, seperated to WM.
		chainPromiseCallsWithoutParameters(promisses).then(result => {
			console.log("All deals posted");
			resolve(result);
		}, reject);
	});
};

const resolveWorkflowData = (data) => {
	const result = {
		...data
	};
	// Set budget 0 if not set
	if (!result.aanmelding_budget) {
		result.aanmelding_budget = "0";
	}

	const opmerkingParts = [];
	opmerkingParts.push(resolveInterests(data).join(", "));
	opmerkingParts.push(resolveServices(data).join(", "));
	if (result.aanmelding_construction) {
		opmerkingParts.push(`${result.aanmelding_construction}`);
	}
	opmerkingParts.push(" ");
	if (data.aanmelding_sustainability) {
		opmerkingParts.push(`Gekozen duurzaamheidsmaatregelen: ${data.aanmelding_sustainability}`);
		opmerkingParts.push(" ");
	}
	if (result.aanmelding_opmerking) {
		opmerkingParts.push(`Verbouwplannen: ${result.aanmelding_opmerking}`);
		opmerkingParts.push(" ");
	}
	if (data.aanmelding_uitvoering) {
		opmerkingParts.push(`Extra informatie uitvoering: ${data.aanmelding_uitvoering}`);
		opmerkingParts.push(" ");
	}
	if (result.aanmelding_opmerkingen) {
		opmerkingParts.push(`Opmerkingen: ${result.aanmelding_opmerkingen}`);
	}

	result.aanmelding_opmerking = opmerkingParts.join("\n");

	return result;
};

export const postWorkflowmanager = (data) => {
	return new Promise((resolve, reject) => {

		const workflowData = resolveWorkflowData(data);

		// See VPS02 `api-oauth2-ibizz-nl` -> teamleader_item for teamleader logs
		// Also see Workflowcard history for logs on automated events.

		// We generate deals based on products selected
		// Every commission has a contact which is set by the filled in form.
		// Every deal / commission workflowcard should be coupled to the correct ambassador
		// Every deal / commission workflowcard should be coupled to the correct product
		// Settings passed by ambassador should result in correct comission values passed to the card.

		let loaderWatch = watch(
			values => [generalData.loading.product, generalData.loading.ambassadeur],
			([product, ambassadeur]) => {
				if (!product && !ambassadeur) {

					// Based on information passed we define our ambassador
					const ambassador = getByPropertyIdAndValue("referralid", data.aanmelding_referralid ?? "", "ambassadeur");
					// console.log("ambassador",ambassador);

					// Now we can post our data
					postContact(workflowData).then((contact) => {
						postDeals(workflowData, contact, ambassador).then(() => {
							// All done, resolve now
							loaderWatch && loaderWatch();
							resolve();
						});
					});
				}
			},
			{
				immediate: true
			}
		);
	})
};