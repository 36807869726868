import { reactive } from "vue";

export const generalData = reactive({
	finished: false,
	summary: false,
	succesful: false,
	showTemplate: false,
	lists: {
		list_id: 13
	},
	settings: {
		"ambassadeur": {},
		"product": {}
	},
	loading: {
		"ambassadeur": true,
		"product": true
	}
});