<template>
    <footer id="Footer">

    </footer>
</template>

<script>
import {defineComponent } from "vue";

export default defineComponent({
    name: "footerElement",
    props: {},
    components: {},
    setup() {
        return {

        };
    },
});
</script>
