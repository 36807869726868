<template>
    <div class="cc-window" v-if="showPopup">
        <div class="cookiecontainer">
            <div class="inner">
                <div class="cc-header">Deze website maakt gebruik van cookies</div>
                <div class="cc-message">
                    <p>We kunnen deze plaatsen voor analyse van onze bezoekersgegevens, om onze website te verbeteren, gepersonaliseerde inhoud te tonen en om u een geweldige website-ervaring te bieden.</p>
                </div>
                <form class="toolscontainer" ref="formRef">
                    <div v-if="showTools" class="cc-tools" v-for="(category, key) in cookieTools" :key="category">
                        <div class="title">{{ category.title }}</div>
                        <div class="cc-tool">
                            <input :id="`cc-tools-${key}`" type="checkbox" :name="key" :checked="checked(key)" :disabled="disabled(key)"/>
                            <label :for="`cc-tools-${key}`">{{ category.label }}</label>
                            <table class="tools" v-if="category.tools.length > 0">
                                <tbody>
                                <tr>
                                    <th class="title">Naam</th>
                                    <th class="descriptions">Doel</th>
                                    <th class="url">Link</th>
                                </tr>
                                <tr v-for="tool in category.tools" :key="tool.name" class="tool">
                                    <td class="title">{{ tool.name }}</td>
                                    <td class="descriptions">{{ tool.description }}</td>
                                    <td class="url">
                                        <a :href="tool.link" target="_blank">Meer informatie</a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </form>
                <button class="cc-link" @click="toggleMoreInfo">
                    <span class="title">{{ moreInfoButtonText }}</span>
                    <span :class="{ rotated: showTools }" v-html="Icons.angle" class="icon"></span>
                </button>
            </div>
            <nav>
                <template v-if="showTools">
                    <button class="more-inline" @click.prevent="handleSave" type="button">Opslaan</button>
                </template>
                <template v-else>
                    <button class="more-inline" @click.prevent="handleChange" type="button">Aanpassen</button>
                </template>
                <button class="more" @click.prevent="handleAccept" type="button">Accepteren</button>
            </nav>
        </div>
    </div>
    <button type="button" @click.prevent="handleOpen" class="cc-revoke" v-if="!showPopup">
        Cookies
    </button>
</template>
<script>

import {computed, defineComponent, provide, ref, reactive, onMounted} from "vue";
import {Icons} from "icons";
export default defineComponent({
    name: "cookieconsent",

    setup() {

        //TODO
        // linkje ergens om popup opnieuw te tonen in footer (fixed)

        const showTools = ref(false);
        const showPopup = ref(!window.localStorage.getItem(ccform_prefix + "ad_personalization"));
        const formRef = ref();

        const cookieValues = reactive({});

        const cookieTools = {
            functionality_storage: {
                title: "Functionele cookies",
                label: "Functionele cookies zorgen ervoor dat de website correct functioneert en zijn strict noodzakelijk",
                tools: [
                    { name: "Systeem", description: "Wordt gebruikt om gebruikersinstellingen en voorkeuren op te slaan, waardoor de functionaliteit van Wingzz wordt verbeterd zonder directe tracking van gebruikersgedrag voor advertentiedoeleinden.", link: "#" },
                    { name: "Google Tag Manager", description: "Wordt gebruikt om gebruikersinstellingen en voorkeuren op te slaan, waardoor de functionaliteit van Google Tag Manager wordt verbeterd zonder directe tracking van gebruikersgedrag voor advertentiedoeleinden.", link: "https://policies.google.com/privacy?hl=en-US" }
                ]
            },
            analytics_storage: {
                title: "Analytische cookies",
                label: "Analytische cookies meten het bezoek van de website. Met deze cookies verzamelen we bezoekersgegevens op een anonieme wijze. Deze gegevens worden gebruikt voor statistische analyses.",
                tools: [
                    { name: "Google Analytics", description: "Wordt gebruikt om het websiteverkeer te analyseren, gebruikersgedrag te volgen en inzichten te bieden in hoe gebruikers de website gebruiken om de prestaties en effectiviteit van de website te verbeteren.", link: "https://policies.google.com/privacy?hl=en-US" }
                ]
            },
            ad_personalization: {
                title: "Social Media cookies",
                label: "Social Media cookies maken het mogelijk om de inhoud van onze website te delen via Social Media. Social Media netwerken doen aan profilering en tracking van bezoekers. Door deze cookies te accepteren kan je internetgedrag gemonitord worden door Social Media.",
                tools:  [
                    { name: "Meta", description: "Registreren van unieke ID's voor websitebezoekers om websitegebruik bij te houden voor statistieken. Deze cookie wordt gebruikt om het gedrag van websitebezoekers vast te leggen voor website-analyse.", link: "https://www.facebook.com/privacy/policies/cookies/?entry_point=cookie_policy_redirect&entry=0" },
                    { name: "Linkedin", description: "Registreren van unieke ID's voor websitebezoekers om websitegebruik bij te houden voor statistieken. Deze cookie wordt gebruikt om het gedrag van websitebezoekers vast te leggen voor website-analyse.", link: "https://nl.linkedin.com/legal/privacy-policy?" }
                ]
            },
            ad_storage: {
                title: "Gepersonaliseerde cookies",
                label: "Met gepersonaliseerde advertentie cookies kunnen advertenties afgestemd op jouw surfgedrag worden getoond. Door deze cookies te accepteren kunnen advertentienetwerken advertenties plaatsen die gericht zijn op de door hun bepaalde doelgroepen.",
                tools: [
                    { name: "Google ads", description: "Wordt gebruikt om advertenties te personaliseren, doelgroepen te segmenteren, conversies toe te schrijven en advertentieprestaties te analyseren.", link: "https://policies.google.com/privacy?hl=en-US" },
                    { name: "Meta", description: "Wordt gebruikt om het surfgedrag van gebruikers te volgen en te analyseren om gerichte advertenties weer te geven op basis van hun interesses en gedrag op de website. Cookie van Meta gebruikt door deelknoppen en advertentietags.", link: "https://www.facebook.com/privacy/policies/cookies/?entry_point=cookie_policy_redirect&entry=0" },
                    { name: "Linkedin", description: "Wordt gebruikt om het surfgedrag van gebruikers te volgen en te analyseren om gerichte advertenties weer te geven op basis van hun interesses en gedrag op de website. Cookie van LinkedIn gebruikt door deelknoppen en advertentietags.", link: "https://nl.linkedin.com/legal/privacy-policy?" }
                ]
            },
            ad_user_data: {
                title: "Advertenties personaliseren",
                label: "Met cookies om advertenties te personaliseren kunnen advertenties worden afgestemd op jouw surfgedrag. Door deze cookies te accepteren kan je surfgedrag gemonitord worden door advertentienetwerken en kunnen adverteerders via een online-veiling advertenties plaatsen bij bezoekers die waarschijnlijk belangstelling hebben voor hun producten of diensten.",
                tools: []
            },
            personalization_storage: {
                title: "Interesse",
                label: "Interesse cookies zorgen ervoor dat we de inhoud van onze website voor jou kunnen optimaliseren, op basis van jouw interesses. Zonder deze cookies krijg je een standaard aanbod op onze website.",
                tools: []
            }
        };

        const disabled = (key) => {
            return key === "functionality_storage";
        };
        const checked = (key) => {
            return computed(() => {
                return cookieValues[key] === "granted";
            }).value;
        };
        onMounted(() => {
            Object.keys(cookieTools).forEach((key) => {
                cookieValues[key] = window.localStorage.getItem(window.ccform_prefix + key) ?? "denied";
            });
        });

        const toggleMoreInfo = () => {
            showTools.value = !showTools.value;
        };

        const moreInfoButtonText = computed(() => {
            return showTools.value ? "Verbergen" : "Meer informatie";
        });

        const handleChange = () => {
            showTools.value = !showTools.value;
        };

        const storeValues = () => {
            Object.keys(cookieValues).forEach((key) => {
                window.localStorage.setItem(window.ccform_prefix + key, cookieValues[key]);
            });
        };
        const updateValues = () => {

            const newValues = {
                ad_personalization: "denied",
                ad_storage: "denied",
                ad_user_data: "denied",
                analytics_storage: "denied",
                personalization_storage: "denied",
                functionality_storage: "granted",
                security_storage: "granted"
            };
            Object.assign(newValues, cookieValues);
            gtag("consent", "update", newValues);
        };

        const handleSave = () => {
            const formData = new FormData(formRef.value);
            const grantedValues = Array.from(formData.keys());

            Object.keys(cookieValues).forEach((key) => {
                if (key === "functionality_storage") {
                    return;
                }
                cookieValues[key] = grantedValues.includes(key) ? "granted" : "denied";
            });

            storeValues();
            updateValues();
            showPopup.value = false;
        };

        const handleAccept = () => {
            // wegklikken popup
            showPopup.value = false;

            // doorgeven aan google tag manager
            Object.keys(cookieTools).forEach((key) => {
                cookieValues[key] = "granted";
            });
            storeValues();
            updateValues();
        };

        const handleOpen = () => {
            showPopup.value = true;
        };

        return {
            formRef,

            showPopup,
            showTools,
            cookieTools,
            Icons,
            toggleMoreInfo,
            moreInfoButtonText,

            handleChange,
            handleSave,
            handleAccept,
            handleOpen,

            checked,
            disabled
        };
    }
});
</script>