// Helper functions
import { mergeDeep } from "helpers/_objects";

import "config/langs";

export const apiHostname = process.env.VUE_APP_API_HOSTNAME;

const config = {

};

// Function which uses default to fallback
// Can be used for example like this 'getConfigValue("product", "cache.expire_duration")'
import * as defaults from './module/_defaults';
export const getConfigValue = (module, keys) => {
    // Merge default config with module config
    const mergedConfig = mergeDeep(defaults, (module in config) ? config[module] : {});

    // Now define our value by walking through our tree
    let value = mergedConfig;
    var namespaces = keys.split(".");
    for (var i = 0; i < namespaces.length; i++) {
        value = value[namespaces[i]];
    }
    return value;
};

export default config;