export function formatData(result, key = "id") {
    if (!result || !result.data || result.data.length === 0) {
        return {};
    }

    // Store result
    const object = JSON.parse(JSON.stringify(result ?? {}));
    // But group the result.data under their id's
    object["data"] = {};
    for (let counter = 0; counter < result.data.length; counter++) {
        const data = result.data[counter].data;
        const extra = result.data[counter].extra;
        if (data) {
            object["data"][data[key]] = {
                extra: extra,
                ...data
            };
        }
    }
    return object;
}

// Returns object, based on FormData or object
export function resolveObject(data) {
	let result = data;
	if (typeof data === "string") {
		let tmpObject;
		try {
			tmpObject = JSON.parse(data);
		} catch (e) { }
		if (tmpObject) {
			result = tmpObject;
		}
	}
    if (data instanceof FormData) {
        result = {};
        data.forEach((value, key) => result[key] = value);
    }
    return result;
}

// Returns only data based on API result
export function resolveData(result) {
    return result.data.map(a => {
        const data = a.data;
        data.extra = a.extra;
        data._meta = a._meta;
		data.images = a.images;
        return data;
    });
}


// Resolve url function
export const resolveUrl = (item) => {
    let url = `/${item.link_module_object.toLowerCase()}/${item.link_item_id}`;
    if (item.link_module_object === "URL") {
        url = item.link_custom_url;
    }
    return url;
};
// Reorder the array based on old and new index
export function reorderArray(arr, old_index, new_index) {
	new_index = new_index >= 0 ? new_index : 0;
	if (new_index >= arr.length) {
		var k = new_index - arr.length + 1;
		while (k--) {
			arr.push(undefined);
		}
	}
	arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
	return arr;
};