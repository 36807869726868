// Defines how long fetched items should be stored
export const cache = {
    // page, application, never
    // page = keeps data only for that page
    // application = keeps data untill refresh or new browser window
    // never = won't expire, could be stored in local storage for example @TODO should be setup that way
    expire_type: "application",
    expire_duration: (10 * 60 * 1000) // 10 minutes
};

// Defines the key value
export const main_key = "data.id";