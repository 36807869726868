import { ref, watch } from "vue";

import { setTranslationObject } from "helpers/_translation";

export const languageOptions = {
	"nl_NL": {
		"label": "Nederlands",
		"data": {
			"Inloggen": "Inloggen",
			"E-mailadres": "E-mailadres",
			"Wachtwoord": "Wachtwoord",
			"Nederlands": "Nederlands",
			"Engels": "Engels",
			"Wachtwoord vergeten" : "Wachtwoord vergeten",
			"Wachtwoord aanvragen" : "Wachtwoord aanvragen"
		}
	},
	"en_GB": {
		"label": "Engels",
		"data": {
			"Inloggen": "Login",
			"E-mailadres": "Email address",
			"Wachtwoord": "Password",
			"Nederlands": "Dutch",
			"Engels": "English",
			"Wachtwoord vergeten" : "Forgot password",
			"Wachtwoord aanvragen" : "Request password"
		}
	}
};

const currentLanguage = ref(window.localStorage.getItem("country_code") ?? Object.keys(languageOptions)?.[0]);

export const setLanguage = (country_code) => {
	currentLanguage.value = country_code;
	window.localStorage.setItem("country_code", country_code);
};
export const getLanguage = () => {
	return currentLanguage.value;
};

watch(
	() => currentLanguage.value,
	(key) => {
		if (!key) return;
		setTranslationObject(languageOptions[key]?.data ?? Object.values(languageOptions)?.[0]?.data);
	},
	{
		immediate: true
	}
);
