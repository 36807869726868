<template>
    <header-element/>
    <div class="App" :class="[componentData.name, { 'iframe': componentData.isIframeView }]">
        <router-view />
    </div>
</template>

<script>
import { defineComponent, reactive, watch, onMounted } from "vue";
import { useRoute } from "vue-router";
import HeaderElement from "layout/header";
import FooterElement from "layout/footer";

export default defineComponent({
    name: "Layout",
    components: {
        HeaderElement,
        FooterElement
    },
    setup() {
        const route = useRoute();

        // Reactive data for the component
        const componentData = reactive({
            name: route.name,
            isIframeView: route.query.view === 'iframe', // Check if url contains ?view=iframe
            color: route.query.color || null // Check for color in the url
        });

        // Watch for changes in the route name
        watch(
            () => route.name,
            (name) => {
                componentData.name = name;
            },
        );

        watch(
            () => route.query.color,
            (color) => {
                componentData.color = color;
                updateCustomColor();
            }
        );

        const updateCustomColor = () => {
            document.documentElement.style.setProperty('--custom-color', componentData.color);
        };

        onMounted(() => {
            updateCustomColor();
        });

        return {
            componentData
        };
    }
});
</script>