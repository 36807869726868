<template>
    <div class="succes" v-if="generalData.showTemplate">
        <div class="inner" v-if="generalData.succesful">
            <span v-html="Icons.succes" class="icon"></span>
            <div class="Heading">
                <span class="title">Aanvraag succesvol ingediend!</span>
            </div>
            <div class="Text">
                <p>Uw aanvraag is succesvol ingediend. We nemen spoedig contact met u op om de details te bespreken.</p>
            </div>

            <button class="more">
                <a href="https://jobsegroep.nl/">
                    <span class="title">Naar Home</span>
                </a>
            </button>
        </div>
        <form class="inner" v-else>
            <div class="loader"></div>
            <div class="Heading">
                <span class="title">Uw aanvraag wordt verstuurd...</span>
            </div>
            <div class="Text">
                <p>Even geduld a.u.b. Uw aanvraag wordt verstuurd.</p>
            </div>
        </form>
    </div>
    <div class="container" v-else>
        <div class="left">
            <div class="Heading">
                <h1 class="h1">Aanvraag op maat</h1>
            </div>

            <div class="Content">
                <p>Goed dat u een aanvraag bij ons doet! Bij het indienen van een aanvraag bij ons:</p>
                <ul>
                    <li>
                        <span v-html="Icons.check" class="icon"></span>
                        <span class="title">Worden gegevens zorgvuldig verwerkt</span>
                    </li>
                    <li>
                        <span v-html="Icons.check" class="icon"></span>
                        <span class="title">Heeft u reactie binnen 1, tot max. 3 werkdagen</span>
                    </li>
                </ul>
            </div>
            <div class="employee">
                <div class="left">
                    <img src="images/danielle.jpg" alt="Danielle, Teamleider Office Support">
                </div>
                <div class="right">
                    <div class="heading">Vragen? Wij helpen graag</div>
                    <div class="function">Daniëlle, Teamleider Office Support</div>
                    <div class="phone">
                        <span v-html="Icons.mobile" class="icon"></span>
                        <a href="callto:0031880334900">+31 88 033 4900</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="right">
            <template v-if="componentData.current_question < conditional(componentData.questions).length">
                <form :name="formName" ref="formRef" method="post" @submit.prevent="handleSubmit" class="cs_form" :class="{loading: componentData.loading_kadaster, intro : componentData.current_question < 1}">

                    <input type="hidden" name="referralid" :value="referralid" />
                    <input type="hidden" name="company" :value="handleAgency" />

                    <template v-for="(question, key) in conditional(componentData.questions)">
                        <fieldset v-if="key === componentData.current_question">
                            <template v-if="componentData.current_question == 0 && componentData.kadaster">
                                <div class="left">
                                    <div class="Heading">
                                        <h3 class="h3">Uw Gegevens</h3>
                                        <h4 class="h4" v-if='!kadaster_error()'>Kloppen uw gegevens? Wijzig uw woning-gegevens hieronder mochten de gegevens niet kloppen.</h4>
                                    </div>
                                </div>
                                <div class="middle" v-if='!kadaster_error()'>
                                    <div class="building-image">
                                      <img :src="'https://maps.googleapis.com/maps/api/streetview?size=600x300&location=' + encodeURIComponent(kadaster('street') + ' ' + kadaster('housenumber') + ' ' + kadaster('postcode') + ' ' + kadaster('city')) + '&key=AIzaSyBtBx97wk8mTMAQZ5ZzGkJAdcyZdTbMITM'" />
                                    </div>
                                    <div class="right">
                                        {{ kadaster("street") }} {{ kadaster("housenumber") }}{{ kadaster("suffix") }}<br>
                                        {{ kadaster("postcode") }} {{ kadaster("city") }}
                                    </div>
                                </div>
                                <div class="middle" v-if='kadaster_error()'>
                                    <p>Momenteel lijkt het erop dat wij uw gegevens niet kunnen ophalen via het Kadaster. U kunt uw aanvraag overigens verder doorlopen.</p>
                                </div>
                                <div class="right" v-if="kadaster_error()">
                                    <div class="input-field span6">
                                        <label for="straatnaam">Straatnaam *</label>
                                        <input id="straatnaam" name="straatnaam" v-model="straatnaam">
                                    </div>
                                    <div class="input-field span6">
                                        <label for="plaats">Plaatsnaam *</label>
                                        <input id="plaats" name="plaats" v-model="plaats">
                                    </div>
                                    <div class="span12" v-if="straatnaam">
                                        Uw adres: {{ Array.isArray(straatnaam) ? straatnaam[0] : straatnaam }} {{ value("huisnummer") }}<template v-if="plaats">, {{ value("postcode") }} {{ Array.isArray(plaats) ? plaats[0] : plaats }}</template>
                                    </div>
                                </div>
                                <div class="right">
                                    <div class="input-field span6">
                                        <label for="building_type">Type gebouw *</label>
                                        <select class="select-field required" name="building_type" :value="value('building_type')">
                                          <option value="" >Kies optie...</option>
                                          <option value="huis">Woning</option>
                                          <option value="bedrijf">Bedrijf</option>
                                        </select>
                                    </div>
                                    <div class="input-field span6">
                                        <label for="year">Bouwjaar *</label>
                                        <input id="year" name="year" :value="kadaster('bouwjaar') ?? value('year')">
                                    </div>
                                    <div class="input-field span6">
                                        <label for="oppervlakte">Oppervlakte *</label>
                                        <input id="oppervlakte" name="oppervlakte" :value="kadaster('oppervlakteBag')?? value('oppervlakte')">
                                    </div>
                                    <div class="labels span6" v-if="kadaster('energieLabelOpnamedatum')">
                                        <div class="actual Label">
                                            <label>Energielabel</label>
                                            <div :class="'energylabel label_' + kadaster('energieLabel')">
                                                {{ kadaster("energieLabel") }}
                                            </div>
                                        </div>
                                        <div class="future Label" v-if="kadaster('bouwjaar') > 1984">
<!--                                            Alleen tonen vanaf 1985-->
                                            <label>Potentieel energielabel</label>
                                            <div :class="'energylabel label_A'">
                                                A
                                            </div>
                                        </div>
                                    </div>
                                    <div class="span12" v-else-if="!kadaster_error()">
                                        Geen energielabel gevonden
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="left">
                                    <div class="Heading">
                                        <h3 class="h3">{{ question.title }}</h3>
                                        <h4 class="h4" v-html="question.content"></h4>
                                    </div>
                                </div>
                            <div class="right questions" :id="question.category">
                                <div class="element" v-if="!question.hasTextInput && !question.hasTextArea" v-for="(answer, index) in conditional(question.answers)">
                                    <div :class="question.multiple === true ? `Checkbox_element` : `Radio_element`">
                                        <input :type="question.multiple === true ? `checkbox` : `radio` "
                                               :name="question.category"
                                               :id="`${answer.title}${index}`"
                                               :value="answer.name"
                                               :checked="checked(question, answer)"
                                        >
                                        <label :for="`${answer.title}${index}`">
                                            <span v-html="Icons[answer.icon]" class="icon"></span>
                                            <div class="Heading">
                                                <span class="title" v-html="answer.title"></span>
                                                <span class="content " v-html="answer.content" v-if="answer.content"></span>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div v-else>
                                    <div v-if="question.hasTextArea || question.hasTextInput" class="input-field" :class="answer.width" v-for="(answer, index) in conditional(question.answers)">
                                        <label :for="answer.title">{{ answer.inputLabel }}</label>
                                        <select v-if="answer.kind === 'select'" :id="answer.title" :name="answer.name" :value="valueByAnswer(question, answer)">
                                            <option value="" >Kies optie...</option>
                                            <option value="heer">Mr.</option>
                                            <option value="mevrouw">Mevr.</option>
                                        </select>
                                        <textarea v-else-if="question.hasTextArea || answer.kind === 'textarea'" :id="answer.title" :name="answer.name" type="text" :value="valueByAnswer(question, answer)">
                                            {{ valueByAnswer(question, answer) }}
                                        </textarea>
                                        <input v-else-if="question.hasTextInput" :id="answer.title" :name="answer.name" type="text" :value="valueByAnswer(question, answer)">
                                    </div>
                                </div>
                            </div>
                            </template>
                            <span class="note" v-if="error">{{ error }}</span>
                        </fieldset>
                    </template>
                    <div class="bottom">
                        <nav>
                            <button type="button" class="more-inline hasbefore" @click.prevent="handlePrev" v-if="componentData.current_question > 0">
                                <span v-html="Icons.angle" class="icon"></span>
                                <span class="title">Vorige</span>
                            </button>
                          <button type="submit" class="more grey" v-if="!isLast() && !isFirst() && componentData.current_question !== (conditional(componentData.questions).length - 1)">
                                <span class="title">Overslaan</span>
                            </button>
                            <button type="submit" class="more bg2" v-if="!isLast()">
                                <span class="title">Verder</span>
                            </button>
                        </nav>
                    </div>
                </form>
            </template>
            <div class="result" v-else>
                <div class="Heading">
                    <h3 class="h3">Samenvatting</h3>
                </div>
                <div class="kadaster_summary">
                    <div class="middle">
                        <div class="building-image">
                          <img :src="'https://maps.googleapis.com/maps/api/streetview?size=600x300&location=' + encodeURIComponent(kadaster('street') + ' ' + kadaster('housenumber') + ' ' + kadaster('postcode') + ' ' + kadaster('city')) + '&key=AIzaSyBtBx97wk8mTMAQZ5ZzGkJAdcyZdTbMITM'" />
                        </div>
                        <div class="right">
                            {{ kadaster("street") }} {{ kadaster("housenumber") }}{{ kadaster("suffix") }}<br>
                            {{ kadaster("postcode") }} {{ kadaster("city") }}
                        </div>
                    </div>
                </div>
                <div class="kadaster_details">
                    <div class="kadaster_date" v-if="componentData.values.uitvoering && componentData.values.uitvoering.length > 0">
                      <div class="title">Uitvoering</div>
                      <div v-for="uitvoeringItem in componentData.values.uitvoering" :key="uitvoeringItem">{{ uitvoeringItem }}</div>
                    </div>
                    <div class="kadaster_surface">
                      <div class="title">Oppervlakte</div>
                      <div class="detail">{{ kadaster("oppervlakteBag") }}</div>
                    </div>
                    <div class="kadaster_energylabel">
                      <div class="title">Energielabel</div>
                      <div class="detail">{{ kadaster("energieLabel") }}</div>
                    </div>
                    <div class="kadaster_future_energylabel">
                      <div class="title">Potentieel energielabel</div>
                      <div class="detail">A</div>
                    </div>
                </div>
                <div class="summary_tags">
                    <div class="summary_details interests" v-if="componentData.values.interests && componentData.values.interests.length > 0">
                        <span class="title">Gekozen Interesses</span>
                        <ul class="tags">
                            <li v-for="interest in componentData.values.interests" :key="interest">{{ valueByCategory("interests", interest) }}</li>
                        </ul>
                    </div>
                    <div class="summary_details services" v-if="componentData.values.services && componentData.values.services.length > 0">
                        <span class="title">Gekozen diensten</span>
                        <ul class="tags">
                            <li v-for="service in componentData.values.services" :key="service">{{ valueByCategory("services", service) }}</li>
                        </ul>
                    </div>
                    <div class="summary_details options" v-if="componentData.values.sustainability && componentData.values.sustainability.length > 0">
                        <span class="title">Gekozen verduurzamings-opties</span>
                        <ul class="tags">
                            <li v-for="sustainabilityOption in componentData.values.sustainability" :key="sustainabilityOption">{{ valueByCategory("sustainability", sustainabilityOption) }}</li>
                        </ul>
                    </div>
                    <div class="summary_details constructions" v-if="componentData.values.construction && componentData.values.construction.length > 0">
                        <span class="title">Bouwmanagement</span>
                        <ul class="tags">
                            <li v-for="constructionmanagement in componentData.values.construction" :key="constructionmanagement">{{ valueByCategory("construction", constructionmanagement) }}</li>
                        </ul>
                    </div>
                </div>
                <div class="summary_input" v-if="componentData.values.opmerking && componentData.values.opmerking.length > 0 || componentData.values.budget && componentData.values.budget.length > 0 || componentData.values.uitvoering && componentData.values.uitvoering.length > 0">
                    <div class="summary_details ideas" v-if="componentData.values.opmerking && componentData.values.opmerking.length > 0">
                        <span class="title">Uw opgegeven verbouwplannen</span>
                        <div v-for="idea in componentData.values.opmerking" :key="idea">{{ idea }}</div>
                    </div>
                    <div class="summary_details budget" v-if="componentData.values.budget && componentData.values.budget.length > 0">
                        <span class="title">Uw budget</span>
                        <div v-for="budgetItem in componentData.values.budget" :key="budgetItem">{{ budgetItem }}</div>
                    </div>
                    <div class="summary_details implementation" v-if="componentData.values.uitvoering && componentData.values.uitvoering.length > 0">
                        <span class="title">Gewenste uitvoerperiode</span>
                        <div v-for="implementation in componentData.values.uitvoering" :key="implementation">{{ implementation }}</div>
                    </div>
                </div>
                <div class="summary_person_details">
                    <div class="summary_details name span12" v-if="componentData.values.voornaam && componentData.values.voornaam.length > 0 && componentData.values.achternaam && componentData.values.achternaam.length > 0">
                        <span class="title">Naam</span>
                        <div class="answer">{{ fullname }}</div>
                    </div>
                    <div class="summary_details email span6" v-if="componentData.values.emailadres && componentData.values.emailadres.length > 0">
                        <span class="title">E-mail</span>
                        <div v-for="details_emailadres in componentData.values.emailadres" :key="details_emailadres" class="anwser">{{ details_emailadres }}</div>
                    </div>
                    <div class="summary_details phone span6" v-if="componentData.values.telefoonnummer && componentData.values.telefoonnummer.length > 0">
                        <span class="title">Telefoonnummer</span>
                        <div v-for="details_phone in componentData.values.telefoonnummer" :key="details_phone" class="anwser">{{ details_phone }}</div>
                    </div>
                    <div class="summary_details Opmerkingen span12" v-if="componentData.values.opmerkingen && componentData.values.opmerkingen.length > 0">
                        <span class="title">Opmerkingen</span>
                        <div v-for="notition in componentData.values.opmerkingen" :key="notition" class="anwser">{{ notition }}</div>
                    </div>
                </div>
                <div class="bottom">
                    <nav>
                        <button type="button" class="more-inline hasbefore" @click.prevent="handlePrev" v-if="componentData.current_question > 0">
                            <span v-html="Icons.angle" class="icon"></span>
                            <span class="title">Vorige</span>
                        </button>
                        <button type="submit" class="more bg2" @click.prevent="handleSend">
                            <span class="title">Aanvraag indienen</span>
                        </button>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent, reactive, ref, provide, onMounted, inject, computed} from "vue";
import {Icons} from "icons";
import trans from "helpers/_translation";
import {postBackup, postKadaster, postBrevo} from "_api";
import { postDataAsync } from "helpers/_api";
import { tmpInput, postWorkflowmanager, resolveWorkflowmanagerData } from "helpers/_workflowmanager";

export default defineComponent({
    name: "dashboardView",
    components: {},
    setup() {


        const error = ref();
        const formRef = ref();
        const handleAgency = ref();
        const generalData = inject("generalData");
        const componentData = inject("componentData");
        const conditional = inject("conditional");

        const queryParams = new URLSearchParams(window.location.search);

        if (queryParams.has('company')) {
            handleAgency.value = queryParams.get('company');
        }

        const referralid = ref(queryParams.get('referralid'));

        const hierarchyMap = {
            "street" : "document.general.address.street",
            "housenumber" : "document.general.address.housenumber",
            "suffix" : "document.general.address.suffix",
            "postcode" : "document.general.address.postcode",
            "city" : "document.general.address.city",
            "image" : "document.general.address.city",
            "base64KadastralImage" : "document.general.base64KadastralImage",
            "energieLabelOpnamedatum" : "document.general.energieLabelOpnamedatum",
            "energieLabel" : "document.general.energieLabel",
            "oppervlakteBag" : "document.object.bagObjectData.oppervlakteBag",
            "bouwjaar" : "document.object.bagObjectData.bouwjaar",
        };
        const kadasterMap = {
            "postcode": "postcode",
            "huisnummer": "huisnummer",
            "huisnummer_toevoeging": "huisnummer_toevoeging"
        };
        const dataMap = {
            "referralid": "referralid",
            "straatnaam" : "straatnaam",
            "plaats" : "plaats",
            "postcode": "postcode",
            "huisnummer": "huisnummer",
            "toevoeging": "toevoeging",
            "building_type": "woningtype",

            "interests": "interests",
            "services": "services",
            "construction": "construction",
            "sustainability": "sustainability",
            "budget": "budget",
            "uitvoering": "uitvoering",
            "opmerking": "opmerking",

            "aanhef": "aanhef",
            "voornaam": "firstname",
            "achternaam": "lastname",
            "emailadres": "email",
            "telefoonnummer": "telefoonnummer",
            "opmerkingen": "opmerkingen",

            "company": "company",
        };

        const validator = {
            "notnull": (name, value) => {
                if (!value) {
                    return false;
                }
                return true;
            },
            "zipcode": (name, value) => {
                // 0000AA of 0000 AA
                const postcodeRegex = /^\d{4}\s?[A-Za-z]{2}$/;
                if (!postcodeRegex.test(value)) {
                    return false;
                }
                return true;
            },
            "housenumber": (name, value) => {
                const housenumberRegex = /^[1-9]\d{0,2}$/;
                if (!housenumberRegex.test(value)) {
                    return false;
                }
                const numericValue = parseInt(value, 10);
                if (isNaN(numericValue) || numericValue < 1 || numericValue > 1000) {
                    return false;
                }
                return true;
            },
            "email": (name, value) => {
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailRegex.test(value)) {
                    return false;
                }

                return true;
            },
            "budget": (name, value) => {
                if ((value === "") || (`${value}` === "0")) {
                    return true;
                }
                const budgetRegex = /^\d+$/;
                if (!budgetRegex.test(value)) {
                    return false;
                }
                const numericValue = parseInt(value, 10);
                if (isNaN(numericValue) || numericValue < 0 || numericValue > 10000000) {
                    return false;
                }
                return true;
            },

            "building_type": (name, value) => {
                if (value !== "huis" && value !== "bedrijf") {
                    return false;
                }
                return true;
            },

            "aanhef": (name, value) => {
              if (value !== "heer" && value !== "mevrouw") {
                return false;
              }
              return true;
            }
        };
        const validateMap = {
            "interests": ["notnull"],
            "postcode": ["notnull", "zipcode"],
            "huisnummer": ["notnull","housenumber"],
            "straatnaam": ["notnull"],
            "plaats": ["notnull"],
            "emailadres": ["notnull", "email"],
            "budget": ["budget"],
            "building_type": ["building_type"],
            "voornaam": ["notnull"],
            "achternaam": ["notnull"],
            "telefoonnummer": ["notnull"],
            "aanhef": ["notnull"]
        };

        const isFirst = () => {
            return componentData.current_question < 1;
        };
        const isLast = () => {
            return componentData.current_question === (conditional(componentData.questions).length);
        };
        const handlePrev = () => {
            componentData.current_question -= 1;
            setTimeout(() => {
                handleSvg();
            }, 50)
        };
        const handleNext = () => {
            if (!isLast()) {
                componentData.current_question += 1;
                setTimeout(() => {
                    handleSvg();
                }, 50);
            } else {
                generalData.finished = true;
            }
        };


        const resolveKadasterData = (data) => {
            const kadasterData = {};
            for (const [key, value] of Object.entries(data)) {
                if (key in kadasterMap) {
                    kadasterData[kadasterMap[key]] = Array.isArray(value) ? value[0] : value;
                }
            }
            return kadasterData;
        };
        const resolveData = (data) => {
            const result = {};
            for (const [key, value] of Object.entries(data)) {
                if (key in dataMap) {
                    const brevoKey = dataMap[key];
                    if (key === "title" && Array.isArray(value)) {
                        result[brevoKey] = value.map(item => ({ title: item.title, content: item.content, icon: item.icon }));
                    } else if (Array.isArray(value)) {
                        result[brevoKey] = value.join(", ");
                    } else {
                        result[brevoKey] = value;
                    }
                }
            }

            result.energielabel = kadaster("energieLabel") ?? "-";
            result.bouwjaar = kadaster("bouwjaar") ?? "-";
            result.oppervlakte = kadaster("oppervlakteBag") ?? "-";
            result.straatnaam = kadaster("street") ?? value("straatnaam");
            result.plaats = kadaster("city") ?? value("plaats");

            return result;
        };


        const handleSubmit = () => {
            if (componentData.loading_kadaster) {
                return;
            }

            const formData = new FormData(formRef.value);

            [...new Set(formData.keys())].forEach(name => {
                componentData.values[name] = formData.getAll(name);

                if (Array.isArray(componentData.values[name]) && componentData.values[name].length === 0) {
                    componentData.values[name] = [];
                }
            });

            const fields = Array.from(formRef.value.querySelectorAll("[name]"));
            const names = [];
            fields.forEach((field) => {
                if (!names.includes(field.name)) {
                    names.push(field.name);
                }
            });


            let valid = true;
            names.forEach(name => {
                if (name in validateMap) {
                    validateMap[name].forEach(key => {
                        if (validator[key](name, formData.get(name)) === false) {
                            valid = false;

                            if (key === "zipcode") {
                                error.value = `Voer de postcode als volgt in: 0000AA of 0000 AA`;
                            } else if (key === "email") {
                                error.value = `E-mailadres is onjuist`;
                            } else if (key === "budget") {
                                error.value = `Voer een geldige getal in zonder komma`;
                            }
                        }
                    });
                }
            });

            if (!valid) {
                // Algemene foutmelding als er geen specifieke foutmelding is ingesteld
                if (!error.value) {
                    error.value = "U dient alles in te vullen";
                }

                setTimeout(() => {
                    error.value = false;
                }, 10000);

                return;
            }


            if (componentData.current_question === 0 && !componentData.kadaster) {
                componentData.loading_kadaster = true;
                postKadaster(resolveKadasterData(componentData.values)).then((result) => {
                    componentData.kadaster = result;
                    componentData.loading_kadaster = false;
                });
                return;
            }

            handleNext();

            if (!isLast()) return;
        };

        const resolveKey = (key, value) => {
            if (key === "company") {
                return "aanmelding_ambassadeur";
            }
            return `aanmelding_${key}`;
        };
        const resolveValue = (key, value) => {
            if (key === "aanhef") {
                return value === "heer" ? "Mr." : "Mevr.";
            }
            return value;
        };

        const resolveBrevoData = (data) => {
            const brevoData = JSON.parse(JSON.stringify(data));
            Object.keys(brevoData).forEach(key => {
                if (!brevoData[key] && !key.endsWith("toevoeging") && (key !== "ambassadeur") && (key !== "referralid")) {
                    brevoData[key] = "-";
                }
            });
            return brevoData;
        };

        const resolveBackupData = (data) => {
            const backupData = {};
            for (const [key, value] of Object.entries(data)) {
                backupData[resolveKey(key, value)] = resolveValue(key, value);
            }
            return backupData;
        };

        const handleSend = () => {
            generalData.showTemplate = true;

            const data = resolveData(componentData.values);
            const backupData = resolveBackupData(data);
            const brevoData = resolveBrevoData(data);

            const allPromises = [];
            allPromises.push(postBackup(backupData)); // Make sure we backup the data, just to be sure no lead will be lost
            allPromises.push(postBrevo(data));
            allPromises.push(postWorkflowmanager(backupData));

            Promise.all(allPromises).then(() => {
                generalData.succesful = true;
            });
        };

        const handleSvg = () => {
            const icons = document.querySelectorAll(".icon");
            if (icons){
                icons.forEach((icon)=>{
                    if(icon.dataset.filename){
                        const filename = icon.dataset.filename;
                        icon.innerHTML = `<img src='/icons/${filename}.svg'></img>`;
                    }
                });
            }
        };

        const checked = (question, value) => {
            return computed(() => {
                return componentData.values[question.category]?.includes(value.name);
            }).value;
        };

        const selectedAnswers = reactive({
            categories: {
                interests: [],
                services: [],
                sustainability: [],
                construction: [],
            },

            personalDetails: {
                salutation: "",
                firstName: "",
                lastName: "",
                email: "",
                phoneNumber: "",
                comment: ""
            }
        });

        onMounted(() => {
            handleSvg();

            // TEMPORARY DISABLED
            // postWorkflowmanager(tmpInput);
        });

        const value = (key) => {
            return computed(() => {
                if (key in componentData.values) {
                    if (componentData.values[key].length > 1) {
                        return componentData.values[key];
                    } else {
                        return componentData.values[key][0];
                    }
                }
                return "";
            }).value;
        }
        const valueByAnswer = (question, answer) => {
            return computed(() => {
                return value(answer.name);
            }).value;
        };

        const valueByCategory = (category_key, value_key) => {
            return computed(() => {
                const category = componentData.questions.find(question => question.category === category_key);
                if (category) {
                    const answer = category.answers.find(answer => answer.name === value_key);
                    if (answer) {
                        return answer.title;
                    }
                }
                return "";
            }).value;
        };


        const fullname = computed(() => {
            const voornaam = value("voornaam");
            const achternaam = value("achternaam");

            return `${voornaam} ${achternaam}`;
        });



        const kadaster = (key) => {
            return computed(() => {
                if (key in hierarchyMap) {
                    const hierarchy = hierarchyMap[key].split(".");
                    let value = componentData.kadaster;
                    hierarchy.forEach((key) => {
                        if (value) {
                            value = value[key];
                        }
                    });
                    return value;
                }
                return componentData.kadaster?.[key] ?? "";
            }).value;
        };
        const kadaster_error = () => {
            return computed(() => {
                return !kadaster("street") && (componentData.kadaster?.error || componentData.kadaster?.message);
            }).value;
        };


        const formName = computed(() => {
            const currentStep = conditional(componentData.questions)[componentData.current_question];
            if (currentStep) {
                let step = componentData.current_question + 1;
                let title = currentStep.title;
                if (step == 1) {
                    // Some exceptions for step 1
                    if (!componentData.kadaster) {
                        step = 0;
                    } else {
                        title = "Uw gegevens";
                    }
                }
                return `form_stap_${step}_${title.replaceAll(" ", "_").toLowerCase().replaceAll("?","")}`;
            }
            return `form`;
        });

        const straatnaam = ref(componentData.values?.straatnaam);
        const plaats = ref(componentData.values?.plaats);

        return {
            referralid,
            handleAgency,

            selectedAnswers,
            componentData,
            generalData,
            formRef,
            Icons,
            error,

            checked,

            isFirst,
            isLast,

            handleSubmit,
            handlePrev,
            handleSend,

            conditional,
            valueByAnswer,
            valueByCategory,

            fullname,

            kadaster,
            kadaster_error,

            formName,

            straatnaam,
            plaats,

            value
        }
    }
});
</script>
