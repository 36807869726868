<template>
    <header id="Header" :class="{ 'iframe': isIframeView() }">
        <div className="container">
            <div className="left">
                <ul className="step-indicator">
                    Stap
                    <li v-for="step in conditional(componentData.questions).length + 1"
                        :key="step"
                        :class="{ current_step: step === componentData.current_question + 1, past_step: step < componentData.current_question + 1 }">
                        {{ step > 4 && step < 6 && conditional(componentData.questions).length < 5 ? '...' : step }}
                    </li>
                </ul>
            </div>
            <div className="middle">
                <Logo/>
            </div>
            <div className="right">
                <a href="https://www.jobsegroep.nl" className="more-inline hasafter">
                    <span v-html="Icons.close" className="icon"></span>
                </a>
            </div>
        </div>
    </header>
</template>

<script>
import { defineComponent, inject } from "vue";
import { Icons } from "icons";
import Logo from "elements/logo";

//helpers
import {getQuery} from "helpers/_structure";

export default defineComponent({
    name: "headerElement",
    components: {
        Logo
    },
    setup() {
        const componentData = inject("componentData");
        const generalData = inject("generalData");
        const conditional = inject("conditional");

        const isIframeView = () => {
            return getQuery("view") === "iframe";
        };

        return {
            componentData,
            conditional,
            generalData,
            Icons,
            isIframeView
        };
    },
});
</script>
